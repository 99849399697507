import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Response} from '../../types/api-types'
import {DrupalLink, PersonNode} from '../../types/content-types'
import {
  ButtonField,
  ExpertParagraph,
  MediaImage,
  Paragraph,
} from '../../types/paragraphs'
import ContentTitles from '../shared/ContentTitles'
import GenericLink from '../shared/Link'
import MediaItem from '../shared/MediaItem'

type Props = {
  paragraph: Paragraph
  response: Response | null
}

const Expert = ({response, paragraph}: Props) => {
  const expertBlockData: ExpertParagraph | undefined = response?.included?.find(
    el => el?.id === paragraph.id,
  )

  const expertPersonData: PersonNode | undefined = response?.included?.find(
    el => el?.id === expertBlockData?.relationships.field_person?.data?.id,
  )

  const expertImageId = expertPersonData?.relationships.field_media?.data?.id

  const expertImage: MediaImage | undefined = response?.included?.find(
    el => el?.id === expertImageId,
  )

  const button: ButtonField | undefined =
    expertBlockData?.attributes.field_button

  return (
    <section className="expert-block section-box overflow-hidden py-5 flex">
      <Container fluid>
        <Row>
          <Col
            lg={{span: 4, offset: 1}}
            className="position-relative expert-block__image"
          >
            <MediaItem
              imageClass="d-none d-md-block "
              response={response}
              item={expertImage}
              objectFit="cover"
              layout="fill"
            ></MediaItem>

            {expertPersonData ? (
              <div className="d-none d-lg-block position-absolute expert-block__person-info bg-purple font-weight-bold">
                <div className="position-relative">
                  <span className="text-white text-uppercase">
                    {expertPersonData.attributes.field_first_name}{' '}
                    {expertPersonData.attributes.field_last_name}
                  </span>
                  {expertPersonData.attributes.field_job_title ? (
                    <>
                      <span className="mx-2 text-white">•</span>
                      <span className="text-white text-uppercase">
                        {expertPersonData.attributes.field_job_title}
                      </span>
                    </>
                  ) : null}
                </div>
              </div>
            ) : null}
          </Col>
          <Col lg={{span: 5, offset: 1}} className="expert-block__info">
            <ContentTitles
              isToptitleCustomColor={true}
              title={expertBlockData?.attributes.field_title}
              toptitle={expertBlockData?.attributes.field_toptitle}
              toptitleColorClass="text-orange"
            />
            {expertBlockData?.attributes.field_text.value ? (
              <span
                className="py-3 d-block expert-block__text"
                dangerouslySetInnerHTML={{
                  __html: expertBlockData?.attributes.field_text.value,
                }}
              ></span>
            ) : null}
            {button ? (
              <>
                {button?.uri?.alias ? (
                  <GenericLink
                    extraClass="btn btn-secondary"
                    link={button as DrupalLink}
                  >
                    {button?.title}
                  </GenericLink>
                ) : (
                  <a
                    href={button?.uri?.uri ? button?.uri?.uri : `#`}
                    className="btn btn-secondary"
                  >
                    {' '}
                    {button?.title}
                  </a>
                )}
              </>
            ) : null}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Expert
